import { CogniteClient } from '@cognite/sdk';
import { CogniteAnnotation, PendingCogniteAnnotation } from './Annotation';

export type PnIDAnnotationType =
  | 'Model Generated'
  | 'User Defined'
  | 'User Confirmed'
  | 'User Deleted'
  | 'Model Recommended';

export const getPnIDAnnotationType = (
  annotation: PendingCogniteAnnotation
): PnIDAnnotationType => {
  if (annotation.status === 'deleted') {
    return 'User Deleted';
  }
  if (annotation.source.startsWith('job')) {
    if (annotation.status === 'unhandled') {
      return 'Model Generated';
    }
    if (annotation.status === 'verified') {
      return 'User Confirmed';
    }
  }
  return 'User Defined';
};

export const linkFileToAssetIds = async (
  client: CogniteClient,
  annotations: CogniteAnnotation[]
) => {
  // update file
  const updates = summarizeAssetIdsFromAnnotations(annotations);

  if (updates.length > 0) {
    await client.files.update(
      updates.map((update) => ({
        ...(update.id
          ? { id: update.id! }
          : { externalId: update.externalId! }),
        update: {
          assetIds: {
            add: Array.from(update.assetIds),
          },
        },
      }))
    );
  }
};

export const summarizeAssetIdsFromAnnotations = (
  annotations: CogniteAnnotation[]
) => {
  type FileAssetIdsMap = {
    id?: number;
    externalId?: string;
    assetIds: Set<number>;
    externalAssetIds: Set<string>;
  };
  return annotations.reduce((prev, annotation) => {
    if (annotation.resourceId && annotation.resourceType === 'asset') {
      let currentEdit = prev.find(
        (change) =>
          change.id === annotation.fileId ||
          change.externalId === annotation.fileExternalId
      );
      if (!currentEdit) {
        // if none for current file, then just add a new one for fileId / fileExternalId
        currentEdit = {
          id: annotation.fileId,
          externalId: annotation.fileExternalId,
          assetIds: new Set<number>(),
          externalAssetIds: new Set<string>(),
        };
        prev.push(currentEdit);
      }
      if (annotation.resourceId) {
        currentEdit.assetIds.add(annotation.resourceId);
      }
      if (annotation.resourceExternalId) {
        currentEdit.externalAssetIds.add(annotation.resourceExternalId);
      }
    }
    return prev;
  }, [] as FileAssetIdsMap[]);
};
