import {
  CogniteClient,
  ExternalEvent,
  CogniteEvent,
  FilesMetadata,
  Asset,
  IdEither,
} from '@cognite/sdk';
import {
  PendingCogniteAnnotation,
  CogniteAnnotation,
  ANNOTATION_EVENT_TYPE,
  ANNOTATION_METADATA_PREFIX,
  CURRENT_VERSION,
} from './Annotation';

export const createAnnotations = async (
  client: CogniteClient,
  pendingAnnotations: PendingCogniteAnnotation[]
): Promise<CogniteAnnotation[]> => {
  const pendingEvents = await convertAnnotationsToEvents(pendingAnnotations);

  let events: CogniteEvent[] = [];

  if (pendingEvents.length > 0) {
    const chunk = 1000;
    for (let i = 0, j = pendingEvents.length; i < j; i += chunk) {
      const temparray = pendingEvents.slice(i, i + chunk);
      events = events.concat(await client.events.create(temparray));
    }

    return pendingAnnotations.map((el, i) => ({
      ...el,
      id: events[i].id,
    })) as CogniteAnnotation[];
  }

  return [];
};

export const listAnnotationsForFile = async (
  client: CogniteClient,
  file: FilesMetadata,
  includeDeleted = false
): Promise<CogniteAnnotation[]> => {
  try {
    const events = (
      await client.events
        .list({
          filter: {
            type: ANNOTATION_EVENT_TYPE,
            metadata: {
              [`${ANNOTATION_METADATA_PREFIX}version`]: `${CURRENT_VERSION}`,
              [`${ANNOTATION_METADATA_PREFIX}file_id`]: `${file.id}`,
            },
          },
        })
        .autoPagingToArray({ limit: -1 })
    ).concat(
      file.externalId
        ? await client.events
            .list({
              filter: {
                type: ANNOTATION_EVENT_TYPE,
                metadata: {
                  [`${ANNOTATION_METADATA_PREFIX}version`]: `${CURRENT_VERSION}`,
                  [`${ANNOTATION_METADATA_PREFIX}file_external_id`]: `${file.externalId}`,
                },
              },
            })
            .autoPagingToArray({ limit: -1 })
        : []
    );

    const annotations = convertEventsToAnnotations(events);
    if (includeDeleted) {
      return annotations;
    }
    return annotations.filter((el) => el.status !== 'deleted');
  } catch (e) {
    return [];
  }
};

export const clearAnnotationsForFile = async (
  client: CogniteClient,
  file: FilesMetadata,
  filterFn = () => true
) => {
  const annotations = await listAnnotationsForFile(client, file, true);
  const toBeDeleted = annotations.filter(filterFn);
  await deleteAnnotations(client, toBeDeleted);
  return toBeDeleted;
};

export const deleteAnnotations = async (
  client: CogniteClient,
  annotations: CogniteAnnotation[]
) => {
  if (annotations.length === 0) {
    return;
  }

  const events = convertAnnotationsToEvents(annotations) as CogniteEvent[];

  const eventChanges = events.map((el) => {
    const update = {
      ...el.metadata,
      [`${ANNOTATION_METADATA_PREFIX}status`]: 'deleted',
    };
    return {
      id: el.id,
      update: {
        metadata: {
          set: update,
        },
      },
    };
  });

  await client.events.update(eventChanges);
};
export const hardDeleteAnnotations = async (
  client: CogniteClient,
  annotations: CogniteAnnotation[]
) => {
  if (annotations.length === 0) {
    return;
  }

  await client.events.delete(annotations.map((el) => ({ id: el.id })));
};

export const listFilesAnnotatedWithAssetId = async (
  client: CogniteClient,
  asset: Asset
): Promise<FilesMetadata[]> => {
  const annotations = await client.events
    .list({
      filter: {
        type: ANNOTATION_EVENT_TYPE,
        metadata: {
          version: `${CURRENT_VERSION}`,
          linkedAssetId: `${asset.id}`,
        },
      },
    })
    .autoPagingToArray({ limit: undefined });

  const externalAnnotations = asset.externalId
    ? await client.events
        .list({
          filter: {
            type: ANNOTATION_EVENT_TYPE,
            metadata: {
              version: `${CURRENT_VERSION}`,
              linkedAssetExternalId: `${asset.externalId}`,
            },
          },
        })
        .autoPagingToArray({ limit: undefined })
    : [];

  const fileIds = new Set<number | string>();
  annotations.concat(externalAnnotations).forEach((item) => {
    if (item.metadata) {
      if (
        item.metadata.fileId &&
        Number.isInteger(Number(item.metadata.fileId))
      ) {
        fileIds.add(Number(item.metadata.fileId));
      } else if (item.metadata.fileExternalId) {
        fileIds.add(item.metadata.fileExternalId);
      }
    }
  });

  if (fileIds.size === 0) {
    return [];
  }
  try {
    return client.files.retrieve(
      Array.from(fileIds).map((id) =>
        typeof id === 'number' ? { id } : { externalId: id }
      )
    );
  } catch (ex) {
    // if theres files that no longer exist, do a new fetch with only valid ids
    ex.errors.forEach((error: { missing?: IdEither[] }) => {
      if (error.missing) {
        error.missing.forEach((el: any) =>
          fileIds.delete(el.externalId || el.id)
        );
      }
    });
    return fileIds.size > 0
      ? client.files.retrieve(
          Array.from(fileIds).map((id) =>
            typeof id === 'number' ? { id } : { externalId: id }
          )
        )
      : [];
  }
};

const convertAnnotationsToEvents = (
  annotations: (PendingCogniteAnnotation | CogniteAnnotation)[]
): (ExternalEvent & { id?: number })[] => {
  return annotations.map((el) => {
    const annotationMetadata = {
      [`${ANNOTATION_METADATA_PREFIX}box`]: JSON.stringify(el.box),
      [`${ANNOTATION_METADATA_PREFIX}status`]: el.status,
      [`${ANNOTATION_METADATA_PREFIX}version`]: `${el.version}`,
      ...(el.fileId && {
        [`${ANNOTATION_METADATA_PREFIX}file_id`]: `${el.fileId}`,
      }),
      ...(el.fileExternalId && {
        [`${ANNOTATION_METADATA_PREFIX}file_external_id`]: `${el.fileExternalId}`,
      }),
      ...(el.resourceId && {
        [`${ANNOTATION_METADATA_PREFIX}resource_id`]: `${el.resourceId}`,
      }),
      ...(el.resourceExternalId && {
        [`${ANNOTATION_METADATA_PREFIX}resource_external_id`]: `${el.resourceExternalId}`,
      }),
      ...(el.resourceType && {
        [`${ANNOTATION_METADATA_PREFIX}resource_type`]: `${el.resourceType}`,
      }),
      ...(el.page && { [`${ANNOTATION_METADATA_PREFIX}page`]: `${el.page}` }),
      ...(el.owner && {
        [`${ANNOTATION_METADATA_PREFIX}owner`]: `${el.owner}`,
      }),
      ...(el.checkedBy && {
        [`${ANNOTATION_METADATA_PREFIX}checked_by`]: `${el.checkedBy}`,
      }),
    };
    return {
      type: ANNOTATION_EVENT_TYPE,
      id: 'id' in el ? el.id : undefined,
      externalId: el.externalId,
      startTime: el.startTime,
      endTime: el.endTime,
      subtype: el.label,
      description: el.description,
      metadata: {
        ...el.metadata,
        ...annotationMetadata,
      },
      source: el.source,
    };
  });
};

const convertEventsToAnnotations = (
  events: CogniteEvent[]
): CogniteAnnotation[] => {
  return events.map((event) => {
    const {
      [`${ANNOTATION_METADATA_PREFIX}status`]: status,
      [`${ANNOTATION_METADATA_PREFIX}box`]: box,
      [`${ANNOTATION_METADATA_PREFIX}version`]: version,
      [`${ANNOTATION_METADATA_PREFIX}file_id`]: fileId,
      [`${ANNOTATION_METADATA_PREFIX}file_external_id`]: fileExternalId,
      [`${ANNOTATION_METADATA_PREFIX}resource_id`]: resourceId,
      [`${ANNOTATION_METADATA_PREFIX}resource_external_id`]: resourceExternalId,
      [`${ANNOTATION_METADATA_PREFIX}resource_type`]: resourceType,
      [`${ANNOTATION_METADATA_PREFIX}page`]: page,
      [`${ANNOTATION_METADATA_PREFIX}owner`]: owner,
      [`${ANNOTATION_METADATA_PREFIX}checked_by`]: checkedBy,
    } = event.metadata!;
    return {
      id: event.id,
      externalId: event.externalId,
      startTime: event.startTime,
      endTime: event.endTime,
      createdTime: event.createdTime,
      lastUpdatedTime: event.lastUpdatedTime,
      description: event.description,
      label: event.subtype,
      fileId: fileId ? Number(fileId) : undefined,
      fileExternalId,
      status,
      box: JSON.parse(box!), // TODO run validation
      resourceId: resourceId ? Number(resourceId) : undefined,
      resourceExternalId,
      resourceType,
      owner,
      checkedBy,
      page: page ? Number(page) : undefined,
      version: Number(version),
      source: event.source,
      metadata: Object.keys(event.metadata!).reduce((prev, key) => {
        if (key.startsWith(ANNOTATION_METADATA_PREFIX)) {
          return prev;
        }
        return { ...prev, [key]: event.metadata![key] };
      }, {} as { [key: string]: string }),
    } as CogniteAnnotation;
  });
};
